import React from 'react';

const FooterPrivacy = () => {
  const htmlMessage = `<p>Wowcher Limited trading as 5pm, Registered in England and Wales (Company number 04264984), Registered Office; Wowcher Limited, Unit 1-2, 19A Fortess Grove, London, England, NW5 2HD. Licenced by the Information Commissioners Office, (registration number: Z3213903).</p>
  <p>© 2024 Wowcher Limited trading as 5pm. All rights reserved. Privacy & Cookies Policy.</p>`;

  return (
    <>
      <footer className="footer">
        <div className="footer__container">
          <div
            dangerouslySetInnerHTML={{
              __html: htmlMessage,
            }}
          />
          <p />
        </div>
      </footer>
      <style jsx>{`
        .footer__container {
          width: 100%;
          max-width: 1024px;
          margin: 0 auto;
        }

        footer {
          position: relative;
          z-index: 1;
          background: #333;
          background: #333;
          color: #fff;
          padding: 15px 10px;
        }
        footer p {
          margin-bottom: 0px;
        }

        @media (min-width: 768px) {
          footer {
            padding: 30px;
          }
          footer p {
            font-size: 1.4rem;
          }
        }
      `}</style>
      <style global jsx>{`
        footer a {
          color: #fff;
          text-decoration: underline;
        }
      `}</style>
    </>
  );
};

export default FooterPrivacy;
